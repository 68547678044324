import React, { useRef, useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import DatePicker from "react-datepicker"
import $ from "jquery"
import "react-datepicker/dist/react-datepicker.css"
import PhoneNumberInput from "react-phone-input-2"
import "react-phone-input-2/lib/plain.css"

const InputField = ({
  startDate,
  setStartDate,
  startTime,
  setStartTime,
  fieldClass,
  labelClass,
  min,
  max,
  type,
  name,
  value,
  required,
  placeholder,
  handlechange,
  pattern,
  label,
  grpmd,
  step,
  filedwrap,
  defaultValue,
  setTelephone,
  telephone,
}) => {
  const [isEditing, setEditing] = useState(false)
  const inputRef = useRef()
  useEffect(() => {
    $("input[name=telephone]").keypress(function (e) {
      //if the letter is not digit then display error and don't type anything
      if (e.which != 8 && e.which != 0 && (e.which < 43 || e.which > 57)) {
        //display error message
        $("#errmsg").html("Digits Only").show().fadeOut("slow")
        return false
      }
    })
  })

  const toggleEditing = () => {
    setEditing(!isEditing)
    if (isEditing) {
      inputRef.current.focus()
    }
  }

  const [country, setCountry] = React.useState("ae")
  // set country code based on cuurent location
  React.useEffect(() => {
    fetch("https://freeipapi.com/api/json/")
      .then(res => res.json())
      .then(data => {
        setCountry(data?.countryCode?.toLowerCase())
      })
  }, [])

  let Comp = <></>

  switch (type) {
    case "datetime":
      Comp = (
        <>
          <div className="input-box dtpicker left-field">
            <label htmlFor="date" className="form-label">
              Preferred Date
            </label>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              minDate={new Date()}
              placeholderText={`Select your preferred date`}
              dateFormat="MMMM d, yyyy"
              className={fieldClass}
              required={required}
              autocomplete="off"
              name={name}
              autoComplete={"" + Math.random()}
              // onChange={handlechange}
              value={startDate}
            />
          </div>
          <div className="input-box dtpicker right-field">
            <label htmlFor="time" className="form-label">
              Preferred Time
            </label>
            <DatePicker
              selected={startDate}
              showTimeSelect
              minDate={new Date()}
              placeholderText={`Select your preferred time`}
              dateFormat="h:mm aa"
              className={fieldClass}
              required={required}
              onChange={date => setStartTime(date)}
              showTimeSelectOnly
              autocomplete="off"
              name="time"
              autoComplete={"" + Math.random()}
              value={startTime}
              selected={startTime}
            />
          </div>
        </>
      )
      break
    case "telephone":
      Comp = (
        <div className="input-box phone-input left-field">
          <label htmlFor={name} className="form-label">
            Phone:*
          </label>
          <PhoneNumberInput
            inputProps={{
              required: true,
              name:name,
              id:name
            }}
            value={telephone}
            onChange={setTelephone}
            country={country}
            enableSearch
            inputClass="input-field input-phone"
            dropdownClass="input-phone-dropdown"
            buttonClass="input-field"
          />
        </div>
      )
      break
    default:
      Comp = (
        <Form.Group
          md={grpmd}
          className={`${type} ${step} ${
            filedwrap ? filedwrap + " input-box" : "input-box"
          }`}
          controlId={"validation" + name}
        >
          {label ? (
            <Form.Label className="form-label">
              {label}
              {required ? "*" : ""}
            </Form.Label>
          ) : (
            ""
          )}

          <Form.Control
            className={fieldClass}
            required={required}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={handlechange}
            pattern={pattern}
            autocomplete="off"
            defaultValue={defaultValue || ``}
          />
        </Form.Group>
      )
  }

  return Comp
}
export default InputField
