import React, { useEffect, useState } from "react"
import Logo from "../../images/icons/logo.svg"
import LogoLight from "../../images/icons/logo-light.svg"
import $ from "jquery"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import BurgerMenu from "./BurgerMenu"
import "./Header.scss"
import EnquireModal from "../EnquireModal/EnquireModal"
import SearchOverlay from "../SearchOverlay/SearchFilter"

import Dropdown from "react-bootstrap/Dropdown"
import { useLocation, useMedia } from "react-use"
import { getWhatsAppURL } from "../../common/misc"
import { SaveItem, useAuthState } from "@myaccount/website-service"
import clsx from "clsx"
import useHasScrolled from "../../hooks/useHasScrolled"
import Share from "../Share/share"
import HeaderHomeMobile from "./HeaderHomeMobile"
import { phoneNumber, whatsAppNo } from "../common/site/utils"
const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || ""

function Header(props) {
  const { noFixed, noScrollAction } = props
  //
  const { state: authState, services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()
  //
  const isHomePage = useLocation().pathname === "/"

  const isMobile = useMedia("(max-width: 767px)")

  const isOffplan = props.layout === "off-plan"
  const isTransparent =
    props.layout === "landing_template" ||
    props.layout === "form_template" ||
    props.layout === "landing_with_search_template" ||
    isOffplan

  const [modalShow, setModalShow] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)
  const [checkmobile, setCheckMobile] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleButtonClick = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const scroll = useHasScrolled()

  const handleOverlay = () => {
    if (showOverlay) setShowOverlay(false)
    else setShowOverlay(true)
  }
  const shareurl = typeof window !== "undefined" ? window.location.href : ""
  const trackerEvent = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: event,
      formType: event + " - " + shareurl,
      formId: event,
      formName: event,
      formLabel: event,
    })
  }

  useEffect(() => {
    // header for only homepage - mobile
    if (isMobile && isHomePage) {
      setCheckMobile(true)
    }
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    {checkmobile ?
      <HeaderHomeMobile /> :
      <header
        className={clsx(`header`, {
          "no-fixed-header": noFixed,
          "header-white no-fixed-header": showOverlay && noFixed,
          "transparent-header": isTransparent && !showOverlay,
          scrolled: !noFixed && scroll,
          "header-white fixed": showOverlay && isTransparent,
          "header-white":
            props.headerclass == "white" || (isHomePage && showOverlay),
        })}
        id="header"
      >
        <>
          <div className="logo-wrap">
            <Link to="/" className="navbar-brand">
              {(isTransparent && scroll == false && !showOverlay) ||
              (noFixed && !showOverlay) ? (
                <img src={LogoLight} alt="logo" className="brand-logo" width={127} height={54} />
              ) : (
                <img src={Logo} alt="logo" className="brand-logo" width={127} height={54}/>
              )}
            </Link>
          </div>
          <div className="header-actions">
            {!isOffplan && (
                (!isMobile ? 
                 <Dropdown
                    className="overlay-dropdown"
                    onToggle={handleOverlay}
                    show={showOverlay}
                    autoClose={false}
                  >
                    <Dropdown.Toggle className="overlay-toggle">
                      <i className="icon-search"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="overlay-menu">
                      <SearchOverlay handleOverlay={handleOverlay} setShowOverlay={setShowOverlay} overlay />
                      {showOverlay && (
                    <div
                      className="search-overlay"
                      onClick={handleOverlay}
                    ></div>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  :
                  <button className="d-flex align-items-center md-action" onClick={handleButtonClick}> <i className="icon-search"></i></button>
                )
            )}
            {isOffplan && (
              <>
                <Share
                  iconclass="icon icon-property-share"
                  text="Share"
                  id={""}
                />
                {!isMobile && (
                  <SaveItem type="property" pid={props?.pid}>
                    <a href="javascript:void(0)" className="save-btn">
                      <i className="icon icon-save-black"></i>{" "}
                      <span className="d-none d-lg-block"><span className='save-text'>Save</span></span>
                    </a>
                  </SaveItem>
                )}
              </>
            )}
            <a href={getWhatsAppURL(whatsAppNo)} target="_blank">
              <i className="icon-whatsapp"></i>
            </a>
            <a
              href={`tel:${phoneNumber}`}
              className="d-flex align-items-center md-action "
              onClick={() => trackerEvent("Phone number click")}
            >
              <i className="icon-phone"></i>
              <span className="d-none d-lg-block header-cta-text">
              {phoneNumber}
              </span>
            </a>

            <button
              className="d-flex align-items-center md-action"
              onClick={() => setModalShow(true)}
            >
              <i className="icon-msg"></i>
              <span className="d-none d-lg-block header-cta-text">Enquire</span>
            </button>
            <a
              href={myaccountURL}
              className="d-flex align-items-center md-action sb-myacc"
            >
              {isAuthenticated ? (
                <i className="icon-user-active"></i>
              ) : (
                <i className="icon-user"></i>
              )}
              <span className="d-none d-lg-block header-cta-text">
                my account
              </span>
            </a>
            {!isOffplan && (
              <Link
                to="/property-valuation-in-dubai/"
                className="btn btn-white d-none d-sm-flex"
              >
                Free Valuation
              </Link>
            )}
            <BurgerMenu />
          </div>
        </>
      </header>
      }
      {isModalOpen ? <SearchOverlay modalShow={isModalOpen} closeModal={closeModal} /> : '' }
      <EnquireModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default Header
