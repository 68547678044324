import React, { useRef, useEffect, useState } from "react"
import Select from "react-select"
import "./SearchOverlay.scss"
import "./SearchFilter.scss"
import { useMedia } from "react-use"
import MoreFiltersModal from "../MoreFiltersModal/MoreFiltersModal"
import $ from "jquery";
import _ from "lodash"
import { isEmpty, filter, find, isArray, isString, get, indexOf } from "lodash"
import { navigate } from "@reach/router"
import { Link, navigate as gatsbyNavigate } from "gatsby";
import { Container, Row, Col, Dropdown, Form , Modal, Tab, Tabs} from "react-bootstrap";
import Slider from "react-slick";
import SearchListBox from "../predictive-search/search-multiareas";
import AreaListBox from "./AreaList";
import { resiSalesPropertyType, bathroomsRange, resiLettingsPropertyType, priceminRangeResiSale, pricemaxRangeResiSale, priceminRangeResiRent, pricemaxRangeResiRent, bedroomsRange, sqftRange, propertyTypes, propertyTypesMap, amenitiesCheckboxArray, Furnishing, ParkingRange, VirtualViewing, bedroomsMinRange, bedroomsMaxRange, sqftMinRange, sqftMaxRange, RentalPeriod, propertyTypesBanner, Developer, completionRange, OffPlanPropertyType } from "../../components/common/propertyUtils";
import { connectRefinementList, RefinementList } from "react-instantsearch-dom"
import Logo from "../../images/icons/logo.svg"


const SearchOverlay = (props) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const isOverlay = props.overlay

  const isProperty = props.department === 'property'
  const isSale = props.sales
  const [modalShow, setModalShow] = useState(false)

  const isTablet = useMedia("(max-width: 1200px)", false)
  const isMobile = useMedia("(max-width: 767px)", false)

  const selectTypes = { PROPERTIES: "properties", OFFPLAN: "offplan" }

  const [selectedType, setSelectedType] = useState(
   url?.includes("off-plan") ? selectTypes.OFFPLAN : selectTypes.PROPERTIES
  )

  const [selectPageURL, setSelectPageURL] = useState(
    selectedType === selectTypes.PROPERTIES ? "property" : "off-plan-properties"
  )

 const isModalOpen = props.modalShow;
 const closeModal = props.closeModal;

 const [showAmenitesList, setShowAmenitesList] = useState(false);

 useEffect(() => {
    // Only collapse the Amenities section initially for mobile devices
    if (isMobile) {
      setShowAmenitesList(false);
    } else {
      setShowAmenitesList(true);
    }
  }, [isMobile]);

  const toggleAmenitesList = () => {
    setShowAmenitesList((prevShow) => !prevShow);
  };


  useEffect(() => {
    setSelectPageURL(
      selectedType === selectTypes.PROPERTIES ? "property" : "off-plan-properties"
    )
  }, [selectedType])

  const handleChnageSelect = (event) => {    
    // redirect if in property results page
    if(!props?.overlay && url?.includes("for-sale") || url?.includes("for-rent")) {
      if (event.target.innerText === "New Developments"){
        setAreaVal('')
        setAreasList([])
        setPropertyVal('')
        setBedVal('')
        setDeveloperVal('')
        setDateVal('')
        setMinPriceVal('')
        setMaxPriceVal('')
        setAmenitesList(amenitiesCheckboxArray)
        gatsbyNavigate("/off-plan-properties/for-sale/in-dubai/")
        // window.location.href = "/off-plan-properties/for-sale/in-dubai/"
      }
      else {
        setAreaVal('')
        setAreasList([])
        setPropertyVal('')
        setBedVal('')
        setAmenitesList(amenitiesCheckboxArray)
        setMinPriceVal('')
        setMaxPriceVal('')
        gatsbyNavigate("/property/for-sale/in-dubai/")
      }
      
      return
    }

    if (event.target.innerText === "New Developments")
      setSelectedType(selectTypes.OFFPLAN)
    else setSelectedType(selectTypes.PROPERTIES)
  }
  
  const buySellOptions = [
    {
      label: "Buy",
      value: "Buy",
    },
    {
      label: "Sell",
      value: "Sell",
    },
  ]


  const tabSection = (
    <div className="tab-section">
      <button
        className={`property-tab-btn tab-btn ${
          selectedType === selectTypes.PROPERTIES ? "selected" : ""
        }`}
        onClick={handleChnageSelect}
      >
        Existing Properties
      </button>
      <button
        className={`develop-tab-btn tab-btn ${
          selectedType === selectTypes.OFFPLAN ? "selected" : ""
        }`}
        onClick={handleChnageSelect}
      >
        New Developments
      </button>
    </div>
  )

  const SearchMultiSeclect = () => (
    <div className="search-box">
      <MoreFiltersModal show={modalShow} onHide={() => setModalShow(false)} />

      <div className="catergory-select">
        <input
          className="select-dropdown"
          name="category"
          placeholder="Community or Tower"
        />
        <i className="icon icon-arrow-down"></i>
      </div>

      {/* <ul className="selected-filters-container">
        <li className="filter-item">
          <p className="filter-text">Dubai Marina</p>
          <i className="icon icon-close"></i>
        </li>
        <li className="filter-item">
          <p className="filter-text">Dubai Marina</p>
          <i className="icon icon-close"></i>
        </li>
        <li className="filter-item">
          <p className="filter-text">Dubai Marina</p>
          <i className="icon icon-close"></i>
        </li>
        <li className="filter-item">
          <button className="clear-btn">Clear All</button>
        </li>
      </ul> */}
    </div>
  )

  // Filter Submit Logic
  const filters = useRef();
  const handleClick = () => {
    filters.current.classList.toggle("is-active");
  }
  // 
  const [areaVal, setAreaVal] = useState('');
  const [amenities, setAmenities] = useState([]);
  const [areaRefineList, setAreaRefineList] = useState([]);
  const [areasArr, setAreasArr] = useState([]);
  const [areasList, setAreasList] = useState([]);
  const [checkedAmenities, setCheckedAmenities] = useState([]);
  const [ismapview, setIsmapview] = useState(true);
  const [amenitesList, setAmenitesList] = useState(amenitiesCheckboxArray);
  const [minpriceVal, setMinPriceVal] = useState('');
  const [maxpriceVal, setMaxPriceVal] = useState('');
  const [minbedroomVal, setMinBedroomVal] = useState('');
  const [maxbedroomVal, setMaxBedroomVal] = useState('');
  const [propertyval, setPropertyVal] = useState('');
  const [developerval, setDeveloperVal] = useState('');
  const [dateval, setDateVal] = useState('');
  const [rentalperiod, setRentalPeriod] = useState('');
  const [furnishval, setFurnishVal] = useState('');
  const [parkingval, setParkingVal] = useState('');
  const [viewingval, setViewingVal] = useState('');
  const [salesVal, setSalesVal] = useState('Buy');
  const [bedVal, setBedVal] = useState('');
  const [bathVal, setBathVal] = useState('');
  const [sqftVal, setSqftVal] = useState('');
  const [minsqftVal, setMinSqftVal] = useState('');
  const [maxsqftVal, setMaxSqftVal] = useState('');
  const [minPriceList, setMinPriceList] = useState([]);
  const [maxPriceList, setMaxPriceList] = useState([]);
  const [minBedroomList, setMinBedroomList] = useState([]);
  const [maxBedroomList, setMaxBedroomList] = useState([]);
  const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
  const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);
  const [selectedMinBedroomValue, setSelectedMinBedroomValue] = useState(0);
  const [selectedMaxBedroomValue, setSelectedMaxBedroomValue] = useState(0);
  const pyOptionsResultPage = props.layout === 'map' ? propertyTypesMap : propertyTypes
  const pyOptions = propertyTypesBanner

  let defaultArea = "dubai";
  const handleMultiAreaFun = (val) => {
    let areas = [];
    if (isEmpty(find(areasArr, val))) {
      areas = [...areasArr]
      areas.push(val)
      setAreasArr(areas)
    }
  }

  const removeArea = (area, clear = false) => {
    if (!clear) {
      let prevAreas = areasArr.filter(x => x.slug !== area)
      setAreaVal('');
      setAreasArr(prevAreas);
    } else {
      setAreasArr([]);
      setAreaVal('');
      // setAreasList([]);
    }
  }



  // Min price
  const onChangeMinPrice = (e) => {
    setSelectedMinPriceValue(e.value);
  }
  // Min price

  // Max Price
  const onChangeMaxPrice = (e) => {
    setSelectedMaxPriceValue(e.value);
  }
  // Max Price

  // Min bedroom
  const onChangeMinBedroom = (e) => {
    setSelectedMinBedroomValue(e.value);
  }
  // Min bedroom

  // Max bedroom
  const onChangeMaxBedroom = (e) => {
    setSelectedMaxBedroomValue(e.value);
  }
  // Max bedroom

  // Sales price list
  var minPrice = [];
  var maxPrice = pricemaxRangeResiSale.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

  priceminRangeResiSale.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));
  if (minPrice.length > 0) {
  } else {
    minPrice.push(priceminRangeResiSale[0])
  }

  if (maxPrice.length > 0) {
  } else {
    maxPrice.push(pricemaxRangeResiSale[0])
  }
  // Sales price list

  // Lettings price list
  var minPriceLettings = [];
  var maxPriceLettings = pricemaxRangeResiRent.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

  priceminRangeResiRent.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPriceLettings.push(items));

  if (minPriceLettings.length > 0) {
  } else {
    minPriceLettings.push(priceminRangeResiRent[0])
  }

  if (maxPriceLettings.length > 0) {
  } else {
    maxPriceLettings.push(pricemaxRangeResiRent[0])
  }


  // Bedroom list
  var minBedroom = [];
  var maxBedroom = bedroomsMaxRange.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinBedroomValue)));

  bedroomsMinRange.map(items => parseInt(items.value) < parseInt(selectedMaxBedroomValue) && minBedroom.push(items));

  if (minBedroom.length > 0) {
  } else {
    minBedroom.push(bedroomsMinRange[0])
  }

  if (maxBedroom.length > 0) {
  } else {
    maxBedroom.push(bedroomsMaxRange[0])
  }
  // Bedroom list


  // Lettings price list

  // useEffect(() => {
  //   var checkedamenities = props.searchState?.refinementList?.amenities
  //   if(checkedamenities) {
  //   if (isArray(checkedamenities) && checkedamenities.length > 0) {
  //     for (var i = 0; i < checkedamenities.length; i++) {
  //       $('.amenities-refine input[type="checkbox"][value="' + checkedamenities[i] + '"]').prop('checked', 'checked');
  //     }
  //   } else {
  //     $('.amenities-refine input[type="checkbox"][value="' + checkedamenities+ '"]').prop('checked', 'checked');
  //   }
  //   }
  // })
  useEffect(() => {
    // 
    if (_.isEmpty(minPriceList)) {
      if (props.type === "lettings") {
        setMinPriceList(priceminRangeResiRent);
        setMaxPriceList(pricemaxRangeResiRent);
      } else {
        setMinPriceList(priceminRangeResiSale)
        setMaxPriceList(pricemaxRangeResiSale)
      }
    }

    if (_.isEmpty(minBedroomList)) {
      setMinBedroomList(bedroomsMinRange)
      setMaxBedroomList(bedroomsMaxRange)
    }
    //

    // Page when load field in text boxes will same
    const url = typeof window !== 'undefined' ? window.location.href : ''
    let pathUri = url.split("/")
    for (let vi = 1; vi <= pathUri.length; vi++) {
      // check for area
      if (typeof pathUri[vi] === "undefined") {
        continue
      }

      // Property type
      if (pathUri[vi].indexOf("type-") >= 0) {
        setPropertyVal(pathUri[vi].replace("type-", ""))
      }
      // Developer
      if (pathUri[vi].indexOf("developer-") >= 0) {
        setDeveloperVal(pathUri[vi].replace("developer-", ""))
      }
      // Completion date
      if (pathUri[vi].indexOf("date-") >= 0) {
        setDateVal(pathUri[vi].replace("date-", ""))
      }

      // Area
      if ((pathUri[vi].indexOf("in-") == 0) && (pathUri[vi].indexOf("in-dubai") == -1)) {
          var areaArrfilt = (pathUri[vi].replace("in-", ""))
          if (areaArrfilt === "dubai") { areaArrfilt = "" }
          props.setAreaList && props.setAreaList(areaArrfilt.split("-and-"));

          var areaTag = areaArrfilt.split("-and-")
          var selectedAreaTag = []
      }
      // Area
      
      //Furnished val
      if (pathUri[vi].indexOf("furnished-") >= 0) {
        setFurnishVal(pathUri[vi].replace("furnished-", ""))
      }

      //ViewingVal
      if (pathUri[vi].indexOf("viewing-") >= 0) {
        setViewingVal(pathUri[vi].replace("viewing-", ""))
      }
      //PriceQualifierVal
      if (pathUri[vi].indexOf("rental-period-") >= 0) {
        setRentalPeriod(pathUri[vi].replace("rental-period-", ""))
      }
      //Parking val
      if (pathUri[vi].indexOf("parking-") >= 0) {
        setParkingVal(pathUri[vi].replace("parking-", ""))
      }
      // SQFT
      if (pathUri[vi].indexOf("below-") >= 0) {
        setSqftVal(pathUri[vi].replace("below-", ""))
      }
      // Area
      // if ((pathUri[vi].indexOf("in-") == 0) && (pathUri[vi].indexOf("in-dubai") == -1)) {
      //   setAreaVal(_.upperFirst(pathUri[vi].replace("in-", "")))
      // }
      // Area

      // Price
      if (
        pathUri[vi].indexOf("between-") >= 0 ||
        pathUri[vi].indexOf("over-") >= 0 ||
        pathUri[vi].indexOf("under-") >= 0
      ) {
        let priceFilt1 = pathUri[vi].split("over-")
        if (priceFilt1[1]) {
          setMinPriceVal(priceFilt1[1])
        }
        let priceFilt2 = pathUri[vi].split("under-")
        if (priceFilt2[1]) {
          setMaxPriceVal(priceFilt2[1])
        }
        let priceFilt3 = pathUri[vi].split("between-")
        if (priceFilt3[1]) {
          let priceFilt4 = priceFilt3[1].split("-and-")
          setMinPriceVal(priceFilt4[0])
          setMaxPriceVal(priceFilt4[1])
        }
      }
      // Price

      // Bedrooms Range
      if (
        pathUri[vi].indexOf("from-") >= 0 ||
        pathUri[vi].indexOf("to-") >= 0 ||
        pathUri[vi].indexOf("bedrooms-to-") >= 0
      ) {
        let bedroomFilt1 = pathUri[vi].split("from-")
        if (bedroomFilt1[1]) {
          var splitbed1 = bedroomFilt1[1].split("-bedrooms")
          if (bedroomFilt1[1].indexOf("to-") <= 0) {
            //console.log("11111 minbed", splitbed1[0]);
            setMinBedroomVal(splitbed1[0])
          }
        }
        let bedroomFilt2 = pathUri[vi].split("to-")
        if (bedroomFilt2[0] == "" && bedroomFilt2[1]) {
          var splitbed2 = bedroomFilt2[1].split("-bedrooms")
          setMaxBedroomVal(splitbed2[0])
          //console.log("11111 maxbed", splitbed2[0]);
        }
        let bedroomFilt3 = pathUri[vi].split("bedrooms-")
        if (bedroomFilt3[0] && bedroomFilt3[1]) {
          if (bedroomFilt3[0].indexOf("from-") >= 0) {
            var splitbed3 = bedroomFilt3[0].split("from-")
            setMinBedroomVal(splitbed3[1].replace(/-/g, ""))
            //console.log("1111122222 minbed", splitbed3[1].replace(/-/g,""));
          }
          if (bedroomFilt3[1].indexOf("to-") >= 0) {
            var splitbed4 = bedroomFilt3[1].split("to-")
            setMaxBedroomVal(splitbed4[1].replace(/-bedrooms/g, ""))
            //console.log("1111122222 maxbed", splitbed4[1].replace(/-bedrooms/g, ""));

          }


        }
      }
      // Bedrooms Range


      // Bedrooms
      
      if(selectedType === "offplan") {
        if (pathUri[vi].indexOf("-and-more-bedrooms") >= 0) {
          setBedVal(pathUri[vi].replace("-and-more-bedrooms", ""))
        }
      } else {
        if (pathUri[vi].indexOf("-bedrooms") >= 0) {
          setBedVal(pathUri[vi].replace("-bedrooms", ""))
        }
      }
      // Bedrooms

      // Bedrooms
      if (pathUri[vi].indexOf("-and-more-bathrooms") >= 0) {
        setBathVal(pathUri[vi].replace("-and-more-bathrooms", ""))
      }
      // Bedrooms

    }
    // Page when load field in text boxes will same
  }, []);
  // useEffect(() => {
  //   if(checkedAmenities.length > 0) {
  //     for (var i = 0; i < checkedAmenities.length; i++) {
  //       $('.amenities-refine input[type="checkbox"][value="' + checkedAmenities[i] + '"]').prop('checked', 'checked');
  //     }
  //   }
  // });
  useEffect(() => {
    // console.log("props.searchState", props.searchState)

    if (props.searchState?.refinementList?.search_areas) {
      let searchAreas = props.searchState.refinementList.search_areas;
      if (searchAreas !== defaultArea) {
        if (isString(searchAreas)) {
          searchAreas = [searchAreas]
        }
        setAreaRefineList(searchAreas);
      }
    }
    // console.log('aaaaaaaaaasearchState1', props.searchState)
    // console.log('aaaaaaaaaasearchState', props.searchState?.refinementList?.amenities)
    var clicked_amenities = props.searchState?.refinementList?.amenities
    if (props.searchState?.refinementList?.amenities) {
      let searchAmenities = props.searchState.refinementList.amenities;
      if (isString(searchAmenities)) {
        searchAmenities = [searchAmenities]
      }
      setAmenities(searchAmenities);
      // console.log("aaaaaaaaaaa", searchAmenities)
      setAmenitesList(
        amenitesList.map(item => searchAmenities.includes(item.value) ? { ...item, checked: true } : { ...item, checked: false }))
      //console.log("amenitesList22222", amenitesList)

    }
    //alert("test");
  }, [props.searchState?.refinementList])

  //console.log("amenitesList", amenitesList)

  React.useEffect(() => {
    // if (amenities.length > 0) {
    //   for (var i = 0; i < amenities.length; i++) {
    //     $('.amenities-refine input[type="checkbox"][value="' + amenities[i] + '"]').prop('checked', 'checked');
    //   }
    // }
    if (props.searchState?.refinementList?.search_areas && areasList.length) {
      let search_areas = props.searchState.refinementList.search_areas;
      if (search_areas !== defaultArea) {
        let defaultRefineItems = []
        if (isString(search_areas)) {
          search_areas = [search_areas]
        }
        search_areas.map((item) => {
          let itemObj = find(areasList, ['slug', item])
          if (!isEmpty(itemObj)) {
            defaultRefineItems.push(itemObj)
          }
        });

        setAreasArr(defaultRefineItems);
        setAreaRefineList(search_areas);
      }

    }
  }, [areasList, props.searchState?.refinementList?.search_areas])

  // Multi area
	React.useEffect(() => {
      let filter = [];
      if (props.searchState?.refinementList?.search_areas || areasList.length > 0) {
          let search_areas = Array.isArray(props.searchState?.refinementList?.search_areas) ? props.searchState?.refinementList?.search_areas : [props.searchState?.refinementList?.search_areas];
          let filterArea = areasList.filter(c => search_areas.includes(c.slug))
          filter.push(...filterArea)
      }
      setAreasArr(filter)
      props.setAreaList && props.setAreaList(filter);
  }, [areasList, props.searchState?.refinementList?.search_areas])
  // Multi area

  const onChangeSellRentResultPage = (value) => {
    gatsbyNavigate(`/${value}`)
  }

  const onChangeSellRent = (value) => {
    setSalesVal(value)
}
// Submit search

const getpTypeTag = () => {
  if (props.ptypetag) return props.ptypetag
  return salesVal === "Buy" ? "for-sale" : "for-rent"
}

const updateSearch = () => {
  var areaValue = []
  if (areaRefineList) {
    let areasToRefine = []
    // let areasForUrl = []
    areasArr.map((a) => {
      if (a) {
        areasToRefine.push(a.slug)
        // areasForUrl.push(a.slug)
      }
    })
    setAreaRefineList(areasToRefine)
    areaValue = areasToRefine
  }
  var amenitiesVal = amenities
  var checkboxes = document.querySelectorAll('.amenities-container input[type=checkbox]:checked')
  for (var i = 0; i < checkboxes.length; i++) {
    amenitiesVal.push(checkboxes[i].value)
  }
  if (amenitiesVal) {
    setAmenities(amenitiesVal);
  }

  setModalShow(false)
}
const removeDuplicates =(arr) => {
    return [...new Set(arr)];
}
  const submitSearch = (e) => {
    $('.price-filter-dropdown').removeClass("active");
    setModalShow(false);
    if(props?.setShowOverlay) {
      props.setShowOverlay(false)
    }
    //console.log('aaaaaaaaaaaprops',props.searchState)
    advancedFilterClose();
    var areaValue = []
    if (areaRefineList) {
      let areasToRefine = []
      // let areasForUrl = []
      areasArr.map((a) => {
        if (a) {
          areasToRefine.push(a.slug)
          // areasForUrl.push(a.slug)
        }
      })
      setAreaRefineList(areasToRefine)
      areaValue = areasToRefine
    }
    var amenitiesVal = []
    var checkboxes = document.querySelectorAll('.amenities-container input[type=checkbox]:checked')
    for (var i = 0; i < checkboxes.length; i++) {
      amenitiesVal.push(checkboxes[i].value)
    }
    if (amenitiesVal) {
        amenitiesVal = removeDuplicates(amenitiesVal);
        setAmenities(amenitiesVal);
    }

    var bedroomValue = bedVal ? bedVal : $(".bedroom-input input[type='hidden']").val();
    var bathroomValue = bathVal ? bathVal : $(".bathroom-input input[type='hidden']").val();
    var buildingValue = propertyval ? propertyval : $(".property input[type='hidden']").val();
    var developerValue = developerval;
    var completionDateValue = dateval;
    let priceUrl = ""
    if (maxpriceVal || minpriceVal) {
      if (minpriceVal && maxpriceVal && minpriceVal != "0" && maxpriceVal != "0") {
        priceUrl = "between-" + minpriceVal + "-and-" + maxpriceVal
      } else
        if ((minpriceVal == '' || minpriceVal == "0") && maxpriceVal && maxpriceVal != "0") {
          priceUrl = "under-" + maxpriceVal
        }
        else if (minpriceVal && (maxpriceVal == '' || maxpriceVal == "0") && minpriceVal != "0") {
          priceUrl = "over-" + minpriceVal
        }
    }
    let bedUrl = ""
    if (maxbedroomVal || minbedroomVal) {
      if (minbedroomVal && maxbedroomVal && minbedroomVal != "0" && maxbedroomVal != "0") {
        bedUrl = "from-" + minbedroomVal + "-bedrooms-" + "to-" + maxbedroomVal + "-bedrooms"
      } else
        if ((minbedroomVal == '' || minbedroomVal == "0") && maxbedroomVal && maxbedroomVal != "0") {
          bedUrl = "to-" + maxbedroomVal + "-bedrooms"
        }
        else if (minbedroomVal && (maxbedroomVal == '' || maxbedroomVal == "0") && minbedroomVal != "0") {
          bedUrl = "from-" + minbedroomVal + "-bedrooms"
        }
    }
    let searchFilterResults = "";
    //console.log("areaValueareaValue", areaValue)
    var keywordareaval = $(".areas-list-wrap .react-autosuggest__input").val();
    //console.log("areaValueareaValue", keywordareaval)
    if (isArray(areaValue) && areaValue.length == 0 && keywordareaval.length > 0) {
      areaValue.push(keywordareaval.replace(/\s+/g, '-'));
    }

    if (isArray(areaValue)) {
      if (areaValue.length > 0) {
        searchFilterResults = "/" + selectPageURL + "/" + getpTypeTag()  + "/in-" + areaValue.join("-and-") + "/";
      } else {
        searchFilterResults = "/" + selectPageURL + "/" + getpTypeTag() + "/in-" + defaultArea + "/";
      }
    } else {
      searchFilterResults = "/" + selectPageURL + "/" + getpTypeTag() + "/in-" + defaultArea + "/";
    }

    if (buildingValue) {
      searchFilterResults += "type-" + buildingValue + "/";
    }
    if (developerValue) {
      searchFilterResults += "developer-" + developerValue + "/";
    }
    if (completionDateValue) {
      searchFilterResults += "date-" + completionDateValue + "/";
    }
    if (bedroomValue && bedroomValue != "0") {
      if(selectedType === "offplan") {
        searchFilterResults += bedroomValue + "-and-more-bedrooms/";
      } else {
        searchFilterResults += bedroomValue + "-bedrooms/";
      }
    }
    if (bathroomValue && bathroomValue != "0") {
      searchFilterResults += bathroomValue + "-and-more-bathrooms/";
    }
    if (furnishval) {
      searchFilterResults += "furnished-" + furnishval + "/";
    }

    if (sqftVal && sqftVal != "0") {
      var sqftValsplit = sqftVal && sqftVal.split("-");
      if (sqftValsplit.length > 1) {
        searchFilterResults += "size-" + sqftValsplit[0] + "-to-" + sqftValsplit[1] + "/";
      }
    }
    //   if(minsqftVal && !maxsqftVal) {
    //     var sqftValsplit = minsqftVal && minsqftVal.split("-");
    //     if(sqftValsplit.length > 1) {
    //       searchFilterResults += "size-"+sqftValsplit[0]+"-to-"+sqftValsplit[1]+"/";
    //     }
    //   }

    //   if (minsqftVal || maxsqftVal) {
    //     if (minsqftVal && maxsqftVal && minsqftVal != "0" && maxsqftVal != "0") {
    //       searchFilterResults = "size-" + minsqftVal+"-to-"+ maxsqftVal
    //     } else{
    //       if ((minsqftVal == '' || minsqftVal == "0") && maxsqftVal && maxsqftVal != "0") {
    //         searchFilterResults = "size-" + maxsqftVal 
    //       }
    //       else if (minsqftVal && (maxsqftVal == '' || maxsqftVal == "0") && minsqftVal != "0") {
    //         searchFilterResults = "size-" + minsqftVal 
    //       }
    //   }
    // }

    let sqftUrl = ""
    if (maxsqftVal || minsqftVal) {
      if (minsqftVal && maxsqftVal && minsqftVal != "0" && maxsqftVal != "0") {
        sqftUrl = "size-" + minsqftVal + "-both-" + maxsqftVal
      } else
        if ((minsqftVal == '' || minsqftVal == "0") && maxsqftVal && maxsqftVal != "0") {
          sqftUrl = "sizetill-" + maxsqftVal
        }
        else if (minsqftVal && (maxsqftVal == '' || maxsqftVal == "0") && minsqftVal != "0") {
          sqftUrl = "sizestarting-" + minsqftVal
        }
    }

    if (isArray(amenitiesVal) && amenitiesVal.length > 0) {
      if (amenitiesVal.length > 1) {
        searchFilterResults += "with-" + amenitiesVal.join("-and-") + "/";
      } else {
        searchFilterResults += "with-" + amenitiesVal + "/";
      }
    }
    if (viewingval) {
      searchFilterResults += "viewing-" + viewingval + "/";
    }
    if (rentalperiod) {
      searchFilterResults += "rental-period-" + rentalperiod + "/";
    }
    if (parkingval) {
      searchFilterResults += "parking-" + parkingval + "/";
    }

    // if(bedroomValue && bedroomValue == 0){
    //   searchFilterResults += "studio/";
    // }

    if (priceUrl) {
      searchFilterResults += priceUrl + "/";
    }
    if (bedUrl) {
      searchFilterResults += bedUrl + "/";
    }
    if (sqftUrl) {
      searchFilterResults += sqftUrl + "/";
    }
    if (props.sort_by) {
      searchFilterResults += 'sortby-' + props.sort_by;
    }

    // props.setAreaList(areasArr.filter(c => typeof c.isDeveloper === 'undefined').map(c =>  c.slug));

    
    // if(isOverlay && typeof window !== 'undefined') {
    //   window.location.href = searchFilterResults;
    // } else {
      navigate(searchFilterResults);
    // }
  }
  // Submit search

  const TriggerAmenities = (e) => {
    // $('.triggermenities').click(function(){
    //   alert('test');
    //   submitSearch();
    // })
    submitSearch();
    document.getElementById("myForm").submit();

  }

  // 
  // Map view
  if (typeof window !== 'undefined' && window.location.pathname.indexOf("/property-map/") == 0) {
    //alert('map-view');
    $('.triggermapview').hide();
  }
  if (typeof window !== 'undefined' && window.location.pathname.indexOf("/property/") == 0) {
    //alert('map-view');
    $('.triggerlistview').hide();
  }
  const mapView = (event) => {
    if (typeof window !== 'undefined') {
      navigate(window.location.pathname.replace("property", "property-map"))
    }
  }
  // Map view
  const ListView = (event) => {

    if (typeof window !== 'undefined') {
      navigate(window.location.pathname.replace("property-map", "property"))
    }
  }

  const clickHandle = (e) => {
    var handleElement = document.getElementById('filter-price-range');
    if (handleElement.classList.contains('active')) {
      handleElement.classList.remove('active');

    } else {
      //console.log("eeeeeeeeeee", e)
      if (e != undefined) {
        const li = e.target.closest('li');
        li.closest('ul').querySelectorAll('li').forEach(item => {
          if (item !== li) {
            item.classList.remove('active');
          }
        })
        e.target.closest('li').classList.toggle('active');
      }
    }
  }
  const clickHandleBed = (e) => {
    var handleElement = document.getElementById('filter-bed-range');
    document.getElementById('filter-price-range').classList.remove('active');
    if (handleElement.classList.contains('active')) {
      handleElement.classList.remove('active');
    } else {
      //console.log("eeeeeeeeeee", e)
      if (e != undefined) {
        const li = e.target.closest('li');
        li.closest('ul').querySelectorAll('li').forEach(item => {
          if (item !== li) {
            item.classList.remove('active');
          }
        })
        e.target.closest('li').classList.toggle('active');
      }
    }
  }
  var checkedList = []
  const GetAmenities = (e, item) => {
    // setAmenities(e);
    //checkedAmenities.push(item);
    // console.log("aaaaaaaaaachecked", item, amenitiesCheckboxArray)

    setAmenitesList(
      amenitesList.map(a =>
        a.value === item
          ? { ...a, checked: !a.checked }
          : a
      ))
    // for (var i = 0; amenitiesCheckboxArray.length > i; i++) {
    //   if (amenitiesCheckboxArray[i].value === item && amenitiesCheckboxArray[i].checked === false) {
    //     amenitiesCheckboxArray[i].checked = true
    //   }
    //   else if (amenitiesCheckboxArray[i].value === item && amenitiesCheckboxArray[i].checked === true) {
    //     amenitiesCheckboxArray[i].checked = false
    //   }
    // }    
  }
  // Advanced filter
  const advancedFilter = (e) => {
    $(".filter-search-overlay").addClass("active-filter")
    $("html").addClass("active-filter")
  }

  const advancedFilterClose = (e) => {
    $(".filter-search-overlay").removeClass("active-filter")
    $("html").removeClass("active-filter")
  }
  const advancedFilterReset = (e) => {
   
    $(".filter-search-overlay").removeClass("active-filter")
    $("html").removeClass("active-filter")
    var ResetsearchFilterResults = "/off-plan-properties/for-sale/in-" + defaultArea + "/";
    if(isProperty) {
      ResetsearchFilterResults = "/property/for-sale/in-"+defaultArea+"/";
      if(props.type === "lettings") {
        ResetsearchFilterResults = "/property/for-rent/in-"+defaultArea+"/";
      } 
    }
  if (typeof window !== "undefined") {
      if (isOverlay) {
        window.location.reload()
      } else {
        window.location.href = ResetsearchFilterResults;
      }
    }
  }

  var accodamationSummaryList = [];


  // Capitalize
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  // Capitalize

  // custom refinementlist
  const CustomRefinementList = ({ amenitesList }) => {

    amenitesList.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    // console.log("amenities_checkboxamenities_checkbox", amenitesList)
    return (
      <div className="amenities-container">
         <p className={`amenities-filter-title ${isMobile ? 'mob-amenities-title' : ''}`} onClick={toggleAmenitesList}>
        Amenities {amenitesList && amenitesList.length > 0 && (
          <i className={showAmenitesList ? "icon-minus" : "icon-plus"}></i>
        )}
      </p>
      {showAmenitesList && (   
            <div className="amenities-filters">
            {amenitesList?.map(item =>
            (
                <div key={item.label} className="checkbox-box">
                <input
                    type="checkbox"
                    id={item.value}
                    name={item.value}
                    value={item.value}
                    checked={item.checked}
                    onChange={(e) => GetAmenities(e, item.value)}
                />
                <label for={item.value}>{capitalize(item.label.replace(/-/g, ' '))}</label>
                </div>
            )
            )}
            </div>        
        )}
      </div>
    );
  }

  const handleMobileTabChange = (key) => {
    if (isOverlay) {
      setSalesVal(key)
      return
    }
    const path = key === "Buy" ? "property/for-sale/in-dubai" : "property/for-rent/in-dubai"
    onChangeSellRentResultPage(path)
  }

  const handlePropertyTypeChange = key => {
    setSelectedType(key)
  }

  const multiSelectInput = (
    <div className="catergory-select areas-list-wrap">
      <SearchListBox areaVal={areaVal} setAreaVal={setAreaVal} setAreasList={setAreasList} handleMultiAreaFun={handleMultiAreaFun} autosuggestInputProps={{ className: 'form-control react-autosuggest__input refine-autosuggest-input' }} placeholder={selectedType === "offplan" ? "Community or Tower" : "Community or Tower"} />
      <i className="icon icon-arrow-down"></i>
    </div>
  )

  const multiSelectOptions = <AreaListBox areas={areasArr} removeArea={removeArea}  />

  const SaleBuySelect = () => (
    <Select
      name="search_type"
      options={isOverlay ? pyOptions : pyOptionsResultPage}
      placeholder="Buy"
      value={isOverlay ? {label: salesVal, value: salesVal} : find(pyOptionsResultPage, ['label', (props.sales ? 'Sale' : 'Rent')])}
      // defaultValue={find(pyOptionsResultPage, ['label', (props.sales ? 'Sale' : 'Rent')])}
      isSearchable={false}
      className="buy-select select-dropdown"
      classNamePrefix={"select"}
      onChange={(e) => isOverlay ? onChangeSellRent(e.value) : onChangeSellRentResultPage(e.value)}
    />
  )

  const DeveloperSelect = () => (
    <Select
      name="search_type"
      options={Developer}
      placeholder="Developer"
      value={Developer.find(obj => obj.value === developerval)}
      // defaultValue={salesVal}
      className="select-developer select select-dropdown select-control"
      classNamePrefix={"select"}
      isSearchable={false}
      onChange={e => setDeveloperVal(e.value)}
    />
  )

  const CompletionDateSelect = () => (
    <Select
      name="search_type"
      options={completionRange}
      placeholder="Completion"
      value={completionRange.find(obj => obj.value === dateval)}
      // defaultValue={salesVal}
      className="selcet-completion select-dropdown select-control"
      classNamePrefix={"select"}
      isSearchable={false}
      onChange={e => setDateVal(e.value)}
    />
  )

  const PropertyTypeSelect = () => (
    <Select
      options={isProperty && isSale ? resiSalesPropertyType : isProperty && !isSale ? resiLettingsPropertyType : OffPlanPropertyType}
      placeholderValue={propertyval ? propertyval : "Property Type"}
      value={isProperty && isSale ? resiSalesPropertyType.find(obj => obj.value === propertyval) : isProperty && !isSale ? resiLettingsPropertyType.find(obj => obj.value === propertyval) : OffPlanPropertyType.find(obj => obj.value === propertyval)}
      onChange={e => setPropertyVal(e.value)}
      isSearchable={false}
      className="property-type select-dropdown"
      classNamePrefix="select"
    />
  )

  const MinPriceSelect = () => (
    <Select
      name="minprice"
      options={parseInt(selectedMaxPriceValue) === 0 ? minPriceList : (props.type === "sales" ? minPrice : minPriceLettings)}
      value={minPriceList.find(obj => obj.value === minpriceVal)}
      isSearchable={false}
      placeholder="Min Price"
      className="min-price select-dropdown"
      classNamePrefix="select"
      onChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
    />
  )

  const MaxPriceSelect = () => (
    <Select
      name="maxprice"
      options={parseInt(selectedMinPriceValue) === 0 ? maxPriceList : (props.type === "sales" ? maxPrice : maxPriceLettings)}
      value={maxPriceList.find(obj => obj.value === maxpriceVal)}
      isSearchable={false}
      placeholder="Max Price"
      className="max-price select-dropdown"
      classNamePrefix="select"
      onChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}

    />
  )

  const BedroomsSelect = () => (
    <Select
      options={bedroomsRange}
      placeholderValue={bedVal ? bedVal + " +" : "Bedrooms"}
      value={bedroomsRange.find(obj => obj.value === bedVal)}
      isSearchable={false}
      className="bedrooms-select select-dropdown"
      classNamePrefix="select"
      onChange={e => setBedVal(e.value)}
    />
  )

  const MobileSearch = () => {
    return <></>
  }

  return (
    <div className={`search-overlay-wrapper ${isOverlay ? "" : "search-filter-wrapper"}`}>
      <div className="overlay-container">
        <div className="overlay-search-section">
        <div >
          {!isMobile && tabSection}
          <div className="overlay-form-section">
            {!isMobile ? (
              <>
                {
                  selectedType === "offplan" ?
                  ""
                  :
                  <>
                    <SaleBuySelect />  
                    <div className="vertical-divider" />
                  </>
                }
                <div className="search-box">
                  {multiSelectInput}
                  {isOverlay && multiSelectOptions}
                </div>

                <div className="vertical-divider" />

                {
                  selectedType === "offplan" ?
                  <>
                    {!isTablet && !isOverlay && <>
                      <DeveloperSelect 
                      classNamePrefix={"select-opt"}
                      className="select-control"
                      />
                      <div className="vertical-divider" />
                    </>}
                    <CompletionDateSelect 
                      classNamePrefix={"select-opt"}
                      className="select-control"
                    />
                    <div className="vertical-divider" />
                  </>
                  :
                  ""
                }

                {!isTablet && (
                  <>
                    <PropertyTypeSelect />  
                    <div className="vertical-divider" />
                    {/* {selectedType === selectTypes.OFFPLAN && (
                      <>
                        <DeveloperSelect />  
                        <div className="vertical-divider" />
                      </>
                    )}
                    {selectedType === selectTypes.OFFPLAN && (
                      <>
                        <CompletionDateSelect />  
                        <div className="vertical-divider" />
                      </>
                    )} */}
                    {/* <div className="price-range select__control">
                      <div className="price-filter">
                        <MinPriceSelect />  
                      </div>
                    </div>
                    <div className="vertical-divider" />
                    <div className="price-range select__control">
                      <div className="price-filter">
                        <MaxPriceSelect />
                      </div>
                    </div> */}
                   <div className="price-range select__control" id="filter-price-range">
                      <ul className="filter-list">
                          <li className="price-filter-dropdown d-flex align-items-center" id="filter-price-range">
                            <button type="button" onClick={clickHandle} className="d-flex align-items-center rangefilter">
                              Price
                              <div className="select__indicator select__dropdown-indicator"></div>
                            </button>
                            <div className="price-filter">
                              <MinPriceSelect />  
                              <MaxPriceSelect />  
                            </div>
                          </li>
                      </ul>
                    </div>
 
                    <div className="vertical-divider" />
                    {/* {selectedType === selectTypes.PROPERTIES && ( */}
                      <>
                        <BedroomsSelect />  
                        <div className="vertical-divider" />
                      </>
                    {/* )} */}
                  </>
                )}
                <div className="d-none">
                  {(props.searchState && amenities.length > 0) && (
                    <>
                    <RefinementList
                      attribute="amenities"
                      defaultRefinement={amenities}
                    />
                    </>
                  )}
                </div>
                <div className="more-filters-box" onClick={() => setModalShow(true)}>
                  <i className="icon icon-sliders-horizontal"></i>
                  <button className="more-filters-btn">
                    More Filters
                    
                  </button>
                  <div className="select__indicator select__dropdown-indicator off-plan-prop-icon"></div>
                </div>

                <button className="search-btn btn btn-black" onClick={submitSearch}>search</button>
              </>
            ) : (
              <div className="search-overlay-mobile">
                <div className="more-filters-modal-wrapper">
                    <Modal                    
                    show={isModalOpen}
                    onHide={closeModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="more-filters-modal"
                    id="filter-modal"
                    fullscreen={"xl-down"}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                            <h3 className="title">More Filters</h3>
                            <Link to='/'><img src={Logo} alt="logo" className="brand-logo" /></Link>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="modal-body-container">
                            <div className="other-filters-container">
                                {tabSection}
                                <div className="other-filters">
                                {!isMobile && multiSelectOptions}
                                <div className="filter-row first-row">
                                    {isMobile ? (
                                        selectedType === "offplan" ?
                                        ""
                                        :
                                        <div className="search-btn-mobile">
                                        <Tabs
                                            defaultActiveKey={props.sales ? 'Buy' : 'Rent'}
                                            className="banner-search-tab-mobile"
                                            onSelect={handleMobileTabChange}
                                        >
                                            <Tab eventKey="Buy" title="Sale"></Tab>
                                            <Tab eventKey="Rent" title="Rent"></Tab>
                                        </Tabs>
                                        </div>
                                    ) : (
                                        <SaleBuySelect />
                                    )}

                                    <div className="search-box">
                                    {isMobile && multiSelectOptions}

                                    {multiSelectInput}
                                    {/* {multiSelectOptions} */}
                                    </div>
                                </div>
                                {
                                    selectedType === "offplan" ?
                                    <div className="filter-row">
                                    <DeveloperSelect 
                                        className="select-control"
                                    />
                                    <CompletionDateSelect />
                                    </div>
                                    :
                                    ""
                                }
                                <div className="filter-row">
                                    <PropertyTypeSelect />
                                    <BedroomsSelect />
                                </div>
                                <div className="filter-row">
                                    <MinPriceSelect />
                                    <MaxPriceSelect />
                                </div>
                                </div>
                            </div>
                            <CustomRefinementList amenitesList={amenitesList}/>
                            <div className="results-btn-section">
                                <button className="update-btn btn btn-black" onClick= {submitSearch}>update search</button>
                                <button className="reset-btn btn btn-white" onClick={advancedFilterReset}>
                                reset
                                </button>
                            </div>
                            
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
            )}
          </div>
          {!isOverlay && !isMobile && multiSelectOptions}
          </div>
        </div>
      </div>

     {/* More Filters Modal */}

     <div className="more-filters-modal-wrapper">
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="more-filters-modal"
          id="filter-modal"
          fullscreen={"xl-down"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h3 className="title">More Filters</h3>
              <Link to='/'><img src={Logo} alt="logo" className="brand-logo" /></Link>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body-container">
              <div className="other-filters-container">
                {tabSection}
                <div className="other-filters">
                  {!isMobile && multiSelectOptions}
                  <div className="filter-row first-row">
                    {isMobile ? (
                        selectedType === "offplan" ?
                        ""
                        :
                        <div className="search-btn-mobile">
                          <Tabs
                            defaultActiveKey={props.sales ? 'Buy' : 'Rent'}
                            className="banner-search-tab-mobile"
                            onSelect={handleMobileTabChange}
                          >
                            <Tab eventKey="Buy" title="Buy"></Tab>
                            <Tab eventKey="Rent" title="Rent"></Tab>
                          </Tabs>
                        </div>
                      ) : (
                        <SaleBuySelect />
                    )}

                    <div className="search-box">
                      {isMobile && multiSelectOptions}

                      {multiSelectInput}
                      {/* {multiSelectOptions} */}
                    </div>
                  </div>
                  {
                    selectedType === "offplan" ?
                    <div className="filter-row">
                      <DeveloperSelect 
                        className="select-control"
                      />
                      <CompletionDateSelect />
                    </div>
                    :
                    ""
                  }
                  <div className="filter-row">
                    <PropertyTypeSelect />
                    <BedroomsSelect />
                  </div>
                  <div className="filter-row">
                    <MinPriceSelect />
                    <MaxPriceSelect />
                  </div>
                </div>
              </div>
              <CustomRefinementList amenitesList={amenitesList}/>
              <div className="results-btn-section">
                <button className="update-btn btn btn-black" onClick={isOverlay ? updateSearch : submitSearch}>
                  {isOverlay ? "update search" : "update results"}
                </button>
                <button className="reset-btn btn btn-white" onClick={advancedFilterReset}>
                  reset
                </button>
              </div>
              
            </div>
          </Modal.Body>
        </Modal>
        <div className="d-none">
          {!modalShow &&
          <CustomRefinementList amenitesList={amenitesList}/> }
          {(props.searchState && areaRefineList.length > 0) && (
            <RefinementList
              attribute="search_areas"
              defaultRefinement={areaRefineList}
            />
          )}
          {(props.searchState && amenities.length > 0) && (
            <RefinementList
              attribute="amenities"
              defaultRefinement={amenities}
            />
          )}
        </div>
      </div>

    </div>
  )
}

export default SearchOverlay
